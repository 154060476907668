import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { Row, Col } from "react-bootstrap";
import Layout from "../components/Common/Layout";
import Content, { HTMLContent } from "../components/Blog/Content";
import PostImage from "../components/Blog/PostImage";
import Container from "../components/Blog/Container";
import SubscribeModal from "../components/Blog/SubscribeModal";
import AuthorDetails from "../components/Blog/AuthorDetails";

// links logos imports
import Facebook from "../img/social/facebook.svg";
import LinkedIn from "../img/social/linkedIn.svg";
import Website from "../img/social/website.svg";
import Clock from "../img/clock.svg";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  author,
  timeToRead,
  localizedData,
  language,
  type
}) => {
  const {
    subscribeButton,
    modalInputPlaceholder,
    share,
    profile,
    follow,
    here,
    getNotified,
    read,
    copied,
  } = localizedData;
  const PostContent = contentComponent || Content;
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [copyMessageVisible, setCopyMessageVisible] = useState(false);
  const [pageLink, setPageLink] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { result } = await addToMailchimp(email);
      if (result === "error") {
        setLoading(false);
        setEmail("");
      } else {
        setModalVisible(true);
        setEmail("");
      }
    } catch (error) {
      setLoading(false);
      setEmail("");
      console.log("Error:", error);
    }
  };

  // get blog page link
  useEffect(() => {
    setPageLink(window.location.href);
  }, []);

  function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.style.opacity = 0;

    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();

    try {
       document.execCommand("copy");
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  }

  function CopyLink() {
    copyTextToClipboard(pageLink);
    setCopyMessageVisible(true);
    setTimeout(() => {
      setCopyMessageVisible(false);
    }, 2500);
  }

  return (
    <section>
      {helmet || ""}
      <Container>
        <Row className="blog-post-header">
          <Col lg={6}>
            <h6 className="mb-3 broCode-description">{description}</h6>
            <h1 className="broCode-header">{title}</h1>
            <div className="readingTime">
              <img src={Clock} alt="reading speed" />
              <p>
                {timeToRead} min {read}
              </p>
            </div>
          </Col>
          <Col className="mt-4 mt-sm-2" lg={{ offset: "1" }}>
            {author.featuredimage ? (
              <PostImage
                image={author.featuredimage}
                alt={`featured image thumbnail for post ${title}`}
                position="right"
              />
            ) : null}
          </Col>
        </Row>
      </Container>
      <Row className="blog-post">
        <Col xl={9} className="article-content">
          {type === 'interview' &&
          <AuthorDetails
            profile={language === "en" ? author.profile : author.profilePt}
            name={author.name}
            socialMediaLinks={author.socialMediaLinks[0]}
            localizedData={{ profile, follow, here }}
          />}
          <PostContent className="post-content" content={content} />
        </Col>
        <Col xl={3} className="links">
          <div>
            <p>{share}</p>
            <div className="links-container">
              <LinkedinShareButton url={pageLink}>
                <div className="link">
                  <img src={LinkedIn} alt="linkedIn" />
                </div>
              </LinkedinShareButton>
              <FacebookShareButton url={pageLink} quote={description}>
                <div className="link">
                  <img src={Facebook} alt="facebook" />
                </div>
              </FacebookShareButton>
              <div
                onClick={CopyLink}
                onKeyDown={CopyLink}
                className="link"
                role="button"
                tabIndex={0}
              >
                <img src={Website} alt="website" />
              </div>
            </div>
            {copyMessageVisible && <div className="link-copied">{copied}</div>}
          </div>
        </Col>
      </Row>
      <Container>
        <div className="subscribe-form">
          <p>{getNotified}</p>
          <form onSubmit={onSubmit}>
            <input
              type="email"
              required
              placeholder={modalInputPlaceholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className={`broCode-button__blue button ${
                loading ? "is-loading" : ""
              }`}
            >
              {subscribeButton}
            </button>
          </form>
        </div>
      </Container>
      {modalVisible && (
        <SubscribeModal setModalVisible={setModalVisible} selectedStep={2} />
      )}
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { post, author, localizedData } = data;
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Layout
      selectedLanguage={localizedData.frontmatter.language}
      location="blog"
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      <BlogPostTemplate
        type={post.frontmatter.type}
        author={author.frontmatter}
        language={localizedData.frontmatter.language}
        localizedData={localizedData.frontmatter}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        wordCount={post.wordCount.words}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        timeToRead={post.timeToRead}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $author: String, $language: String) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      wordCount {
        words
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        author
        type
      }
      timeToRead
    }
    author: markdownRemark(frontmatter: { name: { eq: $author } }) {
      frontmatter {
        name
        profile
        profilePt
        featuredimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialMediaLinks {
          facebookProfile
          linkedInProfile
          twitterProfile
          websiteUrl
        }
      }
    }
    localizedData: markdownRemark(
      frontmatter: {
        language: { eq: $language }
        templateKey: { eq: "blog-index" }
      }
    ) {
      frontmatter {
        language
        subscribeButton
        modalInputPlaceholder
        share
        profile
        follow
        here
        copied
        getNotified
        read
      }
    }
  }
`;
