import React from "react";
import WebsiteBlack from "../../../img/social/websiteBlack.svg";
import LinkedInBlack from "../../../img/social/linkedInBlack.svg";
import FacebookBlack from "../../../img/social/facebookBlack.svg";



function AuthorDetails({ profile, name, socialMediaLinks, localizedData }) {
  return (
    <div className="author-details">
      <h6>{localizedData.profile}</h6>
      <p>{profile}</p>
      <div className="follow-author">
        <h6>
          {localizedData.follow} {name} {localizedData.here}
        </h6>
        {socialMediaLinks.linkedInProfile && (
          <a href={socialMediaLinks.linkedInProfile}>
            <div className="author-links">
              <img src={LinkedInBlack} alt="linked in" />
            </div>
          </a>
        )}
        {socialMediaLinks.facebookProfile && (
          <a href={socialMediaLinks.facebookProfile}>
            <div className="author-links">
              <img src={FacebookBlack} alt="facebook" />
            </div>
          </a>
        )}
        {socialMediaLinks.websiteUrl && (
          <a href={socialMediaLinks.websiteUrl}>
            <div className="author-links">
              <img src={WebsiteBlack} alt="website url" />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default AuthorDetails;
